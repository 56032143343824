@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
:root {
  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 0, 0, 0;
  --background-end-rgb: 0, 0, 0;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}
.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 6px;
  background: conic-gradient(from 135deg at top, currentColor 90deg, #0000 0) 0
      calc(50% - 4px) / 17px 8.5px,
    radial-gradient(
        farthest-side at bottom left,
        #0000 calc(100% - 6px),
        currentColor calc(100% - 5px) 99%,
        #0000
      )
      top right/50% 50% content-box content-box,
    radial-gradient(
        farthest-side at top,
        #0000 calc(100% - 6px),
        currentColor calc(100% - 5px) 99%,
        #0000
      )
      bottom / 100% 50% content-box content-box;
  background-repeat: no-repeat;
  animation: l11 1s infinite linear;
}
@keyframes l11 {
  100% {
    transform: rotate(1turn);
  }
}
html {
  background-image: linear-gradient(
    80deg,
    hsl(0deg 0% 0%) 0%,
    hsl(0deg 3% 1%) 9%,
    hsl(0deg 3% 2%) 16%,
    hsl(0deg 3% 3%) 24%,
    hsl(0deg 3% 4%) 32%,
    hsl(0deg 3% 5%) 40%,
    hsl(0deg 3% 6%) 48%,
    hsl(0deg 3% 6%) 56%,
    hsl(0deg 2% 7%) 65%,
    hsl(0deg 2% 8%) 74%,
    hsl(0deg 2% 8%) 85%,
    hsl(0deg 2% 9%) 100%
  );
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
  transition-property: opacity, transform, filter, background;
  transition-timing-function: ease-in-out;
  transition-duration: 300ms;
  background: #232526; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #414345,
    #232526
  ); /* Chrome 10-25, Safari 5.1-6 */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.page-transition {
  opacity: 0.1;
  background: black;
  transform: translateY(10px);
  filter: blur(12px);
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.rewards-gradient {
  background: rgb(255, 0, 0);
  background: linear-gradient(
    24deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(45, 63, 253, 1) 100%
  );
}

.embla {
  max-width: 48rem;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 2rem;
  --slide-size: 50%;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
}
.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.8rem;
}
.embla__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
}
.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla__button:disabled {
  color: var(--detail-high-contrast);
}
.embla__button__svg {
  width: 35%;
  height: 35%;
}

[class^="number-slide"],
[class*=" number-slide"],
.slide {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 300px;
  max-height: 100vh;
}

.flipped .back {
  transform: rotateY(180deg);
}
.flipped .front {
  transform: rotateY(0);
}

.flipped:hover .back {
  transform: rotateY(0deg);
}
.flipped:hover .front {
  transform: rotateY(180deg);
}

nput::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.responsive-iframe-container {
  max-width: 80%;
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  margin: auto;
  border-radius: 15px;
}
